import * as React from "react";
import Link from "../../components/Link";
import Seo from "../../components/Seo";
import "./styles.scss";

const Projects = () => (
  <>
    <Seo title="Projects" />
    <section id="page-404" className="section is-medium">
      <h3 id="message-404">
        <span role="img" aria-label="Construction Sign">
          🚧
        </span>{" "}
        UNDER CONSTRUCTION{" "}
        <span role="img" aria-label="Construction Sign">
          🚧
        </span>
      </h3>
      <h4 id="message-404-subtitle">
        Shall we go back <Link to="/">home</Link>?
      </h4>
    </section>
  </>
);

export default Projects;
